import React from 'react';
import { jsx, Text, Container, Flex, AspectRatio } from 'theme-ui';

import { graphql } from 'gatsby';

import { BlockContent } from '../components/block-content';
import SEO from '../components/seo';
import { Row, Grid, Col } from '../components/grid';
import GraphQLErrorList from '../components/graphql-error-list';
import { PromoBanner } from '../components/promo-banner';

export const query = graphql`
  query PageQuery($id: String!) {
    page: sanityPage(_id: { eq: $id }) {
      title
      seo {
        ...SanityPageSeoFragment
      }
      promoBanner {
        ...SanityPromoBannerFragment
      }
      content {
        text: _rawText(resolveReferences: { maxDepth: 10 })
      }
    }
  }
`;

const PageTemplate = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <>
        <GraphQLErrorList errors={errors} />
      </>
    );
  }

  return (
    <>
      <SEO {...data.page.seo} />
      {data.page.promoBanner && <PromoBanner {...data.page.promoBanner} />}
      {data.page.content.map(({ text }, i) => (
        <Container
          key={`content_${i}`}
          sx={{ borderTop: i > 0 ? '1px solid' : null, borderColor: 'line' }}
        >
          <Row>
            <Grid columns={[12]}>
              <Col start={[1, 2, 3]} span={[12, 11, 10]}>
                <BlockContent blocks={text} />
              </Col>
            </Grid>
          </Row>
        </Container>
      ))}
    </>
  );
};
export default PageTemplate;
